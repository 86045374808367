import React from 'react'
import { useHistory } from 'react-router-dom'
import Logo from '../../Layout/Logo/Logo'
import './NavBar.css'
import DrawerToggleButton from './SideDrawer/DrawerToggleButton'

function NavBar({ setDrawerOpen, drawerOpen }) {
  const history = useHistory()
  const navItems = [
    {
      name: 'Home',
      href: '/',
      subItems: [],
    },
    {
      name: 'Chutemundo',
      href: '/chutemundo',
      subItems: [],
    },
    {
      name: 'Cómo se Juega',
      href: '/reglas',
      subItems: [],
    },
    {
      name: 'Tiendas',
      href: '/tiendas',
      subItems: [],
    },
    {
      name: 'Arma tu DT',
      href: '/creator',
      subItems: [],
    },
    {
      name: 'Contacto',
      href: '/contacto',
      subItems: [],
    },
    {
      name: 'Compra Aquí',
      href: '/comprar',
      subItems: [],
    },
  ]

  const getItemUrls = (item) => {
    const itemUrls = []
    itemUrls.push(item.href)
    item.subItems.forEach((sub) => {
      itemUrls.push(sub.href)
      sub.subItems.forEach((double) => {
        itemUrls.push(double.href)
      })
    })

    return itemUrls
  }

  const createDoubleSubTabs = (subItems) =>
    subItems.map((item) => (
      <button
        key={item.name}
        className="double-sub-tab-button"
        type="button"
        onClick={() => {
          history.push(item.href)
        }}
      >
        {item.name}
      </button>
    ))

  const createSubTabs = (subItems) =>
    subItems.map((item) => (
      <div key={item.name} className="navbar-double-sub-tab">
        <button
          className="sub-tab-button"
          type="button"
          onClick={() => {
            history.push(item.href)
          }}
        >
          {item.name}
        </button>
        {item.subItems.length > 0 ? (
          <div className="navbar-doble-sub-container">
            {createDoubleSubTabs(item.subItems)}
          </div>
        ) : null}
      </div>
    ))

  const createTabs = () =>
    navItems.map((item) => {
      const itemUrls = getItemUrls(item)
      return (
        <div
          key={item.name}
          className=
          
          {`${
            itemUrls.includes(window.location.pathname)
              ? 'navbar-tab active-tab'
              : 'navbar-tab'
          } ${item.href ==='/comprar'? 'green-button' : ''} `}
        >
          <button
            className={`tab-button ${item.href ==='/comprar'? 'light-green-button desktop' : ''} `}
            type="button"
            onClick={() => {
              history.push(item.href)
            }}
          >
            {item.name}
          </button>
          {item.subItems.length > 0 ? (
            <div className="navbar-sub-container">
              {createSubTabs(item.subItems)}
            </div>
          ) : null}
        </div>
      )
    })

  const createShoppingButton = () => {
    const shoppingItem = navItems.find((item) => item.href === '/comprar')
    return (
      <div className="shopping-button-container">
        <button
          className="navbar-shopping-button light-green-button"
          type="button"
          onClick={() => {
            history.push(shoppingItem.href)
          }}
        >
          {shoppingItem.name}
        </button>
      </div>
    )
  }
  return (
    <div className="navbar-container">
      <div className="navbar-burger-button">
        <DrawerToggleButton handleClick={() => setDrawerOpen(!drawerOpen)} />
      </div>
      <div className="logo">
        <Logo className="navbar-logo" />
      </div>
      <div className="navbar-tabs-container">{createTabs()}</div>
      {createShoppingButton()}
    </div>
  )
}

export default NavBar
