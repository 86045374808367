import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../Layout/Logo/Logo'
import './Footer.css'

const Footer = () => {
  const footerItems = [
    {
      type: 'link',
      name: 'Home',
      href: '/',
      subItems: [],
    },
    {
      type: 'link',
      name: 'Chutemundo',
      href: '/chutemundo',
      subItems: [],
    },
    {
      type: 'link',
      name: 'Cómo se Juega',
      href: '/reglas',
      subItems: [
        {
          type: 'link',
          name: 'Manual de Juego',
          href: '/reglas#manual',
          subItems: [],
        },
        {
          type: 'link',
          name: 'FAQ',
          href: '/reglas#faq',
          subItems: [],
        },
      ],
    },
    {
      type: 'link',
      name: 'Compra Aquí',
      href: '/comprar',
      subItems: [],
    },
    {
      type: 'link',
      name: 'Tiendas',
      href: '/tiendas',
      subItems: [],
    },
    {
      type: 'link',
      name: 'Arma tu DT',
      href: '/creator',
      subItems: [],
    },
    {
      type: 'link',
      name: 'Contacto',
      href: '/contacto',
      subItems: [
        {
          type: 'info',
          name: 'contacto@morsagames.com',
        },
      ],
    },
  ]
  const socialNetworkLinks = {
    facebook_url: 'https://www.facebook.com/morsagames',
    instagram_url: 'https://www.instagram.com/morsagames',
  }
  const createSubItems = (subItems) =>
    subItems.map((item) => {
      return (
        <div key={item.name} className="footer-sub-item-container">
          {item.type === 'link' ? (
            <Link className="footer-sub-item-link" to={item.href}>
              {item.name}
            </Link>
          ) : (
            <p className="footer-sub-item-info">{item.name}</p>
          )}
        </div>
      )
    })

  const createItems = () =>
    footerItems.map((item) => {
      return (
        <div key={item.name} className="footer-item-container">
          {item.type === 'link' ? (
            <Link className="footer-item-link" to={item.href}>
              {item.name}
            </Link>
          ) : (
            <p className="footer-item-info">{item.name}</p>
          )}
          {item.subItems.length > 0 ? (
            <div className="footer-sub-items-container">
              {createSubItems(item.subItems)}
            </div>
          ) : null}
        </div>
      )
    })

  return (
    <div className="footer">
      <div className="footer-logo-social-container">
        <div className="footer-horizontal-line" />
        <Logo className="footer-logo" />
        <div className="footer-social-network-container">
          <div className="social-network-row">
            <h2 className="social-network-title">
              Síguenos en nuestras Redes Sociales!
            </h2>
          </div>
          <div className="social-network-row icons-container">
            {/* <div className="social-network-icon">
              <a
                href={socialNetworkLinks.whatsapp_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Whatsapp"
                  src="/img/whatsapp.png"
                  className="social-network-img"
                />
              </a>
            </div> */}
            <div className="social-network-icon">
              <a
                href={socialNetworkLinks.facebook_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Facebook"
                  src="/img/facebook.png"
                  className="social-network-img"
                />
              </a>
            </div>
            <div className="social-network-icon">
              <a
                href={socialNetworkLinks.instagram_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Instagram"
                  src="/img/instagram.png"
                  className="social-network-img"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-horizontal-line" />
      </div>
      <div className="footer-items-container">{createItems()}</div>
      <div className="logo-corfo">
        <img src="/img/CORFO.png" alt="corfo" />
      </div>
    </div>
  )
}

export default Footer
