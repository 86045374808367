/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useLocation, matchPath } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  File as FileIcon,
  BarChart as BarChartIcon,
  User as UserIcon,
  Users as UsersIcon,
  Home as home,
  ShoppingBag,
} from 'react-feather'
import Logo from '../../Layout/Logo/Logo'
import NavItem from './NavItem'

const navConfig = [
  {
    subheader: 'Reportes',
    items: [
      {
        title: 'Análisis General',
        icon: BarChartIcon,
        href: '/admin/reportes',
      },
    ],
  },
  {
    subheader: 'Administrativo',
    items: [
      {
        title: 'Administradores',
        icon: UserIcon,
        href: '/admin/administradores',
      },
      {
        title: 'Productos',
        icon: ShoppingCartIcon,
        href: '/admin/productos',
      },
      {
        title: 'Packs',
        icon: ShoppingCartIcon,
        href: '/admin/packs',
      },
      {
        title: 'Pedidos',
        icon: FileIcon,
        href: '/admin/pedidos',
      },
      {
        title: 'Categorías',
        icon: FolderIcon,
        href: '/admin/categorias',
      },
      {
        title: 'Cupones',
        icon: ShoppingBag,
        href: '/admin/cupones',
      },
      {
        title: 'Vistas Home',
        icon: home,
        href: '/admin/vistasHome',
      },
      {
        title: 'Equipos',
        icon: UsersIcon,
        href: '/admin/equipos',
      },
      {
        title: 'Jugadores / DTs',
        icon: UserIcon,
        href: '/admin/jugadores',
      },
      {
        title: 'Tiendas',
        icon: home,
        href: '/admin/tiendas',
      },
    ],
  },
]

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    )
  }

  return acc
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 100,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logo: {
    maxWidth: '300px',
  },
}))

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles()
  const location = useLocation()
  const { user } = useSelector((state) => state.account)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/admin/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/admin/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.name}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func.isRequired,
  openMobile: PropTypes.bool.isRequired,
}

export default NavBar
