/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import './BackDrop.css'

const BackDrop = ({ setDrawerOpen }) => (
  <button
    type="button"
    className="backdrop"
    onClick={() => setDrawerOpen(false)}
  />
)

export default BackDrop
