import React from 'react'
import './DrawerToggleButton.css'

const DrawerToggleButton = (props) => (
  <button
    type="button"
    className="toggle-button"
    onClick={() => props.handleClick()}
  >
    <div className="toggle-button-line" />
    <div className="toggle-button-line" />
    <div className="toggle-button-line" />
  </button>
)

export default DrawerToggleButton
