import React from 'react'
import './SideDrawer.css'
import { Link } from 'react-router-dom'

const SideDrawer = ({ show, setDrawerOpen }) => {
  let className = 'side-drawer'
  if (show) {
    className = 'side-drawer open'
  }
  const navItems = [
    {
      name: 'Home',
      href: '/',
      subItems: [],
    },
    {
      name: 'Chutemundo',
      href: '/chutemundo',
      subItems: [],
    },
    {
      name: 'Cómo se Juega',
      href: '/reglas',
      subItems: [],
    },
    {
      name: 'Tiendas',
      href: '/tiendas',
      subItems: [],
    },
    {
      name: 'Arma tu DT',
      href: '/creator',
      subItems: [],
    },
    {
      name: 'Contacto',
      href: '/contacto',
      subItems: [],
    },
    {
      name: 'Compra Aquí',
      href: '/comprar',
      subItems: [],
    },
  ]
  const createNavItem = (item) => {
    return (
      <li key={item.href}>
        <Link to={item.href} onClick={() => setDrawerOpen(false)}>
          {item.name}
        </Link>
      </li>
    )
  }
  return (
    <nav className={className}>
      <ul> {navItems.map((item) => createNavItem(item))} </ul>
    </nav>
  )
}

export default SideDrawer
