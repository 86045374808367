import React, { useState } from 'react'
import PropTypes from 'prop-types'
import NavBar from './NavBar'
import SideDrawer from './NavBar/SideDrawer/SideDrawer'
import BackDrop from './NavBar/BackDrop/BackDrop'
import Footer from './Footer'

function EcommerceLayout({ children }) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const handleDrawerOpenClick = (isDrawerOpen) => {
    if (isDrawerOpen) {
      window.onscroll = () => {
        setDrawerOpen(false)
      }
    }
    setDrawerOpen(isDrawerOpen)
  }
  return (
    <div>
      <NavBar setDrawerOpen={handleDrawerOpenClick} drawerOpen={drawerOpen} />
      <SideDrawer show={drawerOpen} setDrawerOpen={handleDrawerOpenClick} />
      {drawerOpen && <BackDrop setDrawerOpen={setDrawerOpen} />}
      <div>{children}</div>
      <Footer />
    </div>
  )
}

EcommerceLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default EcommerceLayout
