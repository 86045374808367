/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, Fragment, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthGuard from './components/Auth/AuthGuard'
import LoadingScreen from './components/Loading/LoadingScreen'
import AdminLayout from './components/AdminLayout'
import EcommerceLayout from './components/EcommerceLayout'

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/Error404/Error404View')),
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: lazy(() => import('./views/admin/Login/Login')),
  },
  {
    id: 100,
    exact: true,
    path: '/reserva-gracias',
    component: lazy(() => import('./views/landings/LandingSuccess')),
  },
  {
    id: 101,
    exact: true,
    path: '/reserva',
    component: lazy(() => import('./views/landings/Landing')),
  },
  {
    id: 3,
    path: '/admin',
    guard: AuthGuard,
    layout: AdminLayout,
    routes: [
      {
        id: 4,
        exact: true,
        path: '/admin',
        component: () => <Redirect to="/admin/administradores" />,
      },
      {
        id: 5,
        exact: true,
        path: '/admin/administradores',
        component: lazy(() => import('./views/admin/admins/AdminListView')),
      },
      {
        id: 6,
        exact: true,
        path: '/admin/administradores/nuevo',
        component: lazy(() => import('./views/admin/admins/AdminCreateView')),
      },
      {
        id: 7,
        exact: true,
        path: '/admin/administradores/:adminId/editar',
        component: lazy(() => import('./views/admin/admins/AdminEditView')),
      },
      {
        id: 8,
        exact: true,
        path: '/admin/productos',
        component: lazy(() => import('./views/admin/products/ProductListView')),
      },
      {
        id: 9,
        exact: true,
        path: '/admin/productos/nuevo',
        component: lazy(() =>
          import('./views/admin/products/ProductCreateView')
        ),
      },
      {
        id: 10,
        exact: true,
        path: '/admin/productos/:productId/editar',
        component: lazy(() => import('./views/admin/products/ProductEditView')),
      },
      {
        id: 11,
        exact: true,
        path: '/admin/categorias',
        component: lazy(() => import('./views/admin/groups/GroupListView')),
      },
      {
        id: 12,
        exact: true,
        path: '/admin/categorias/nuevo',
        component: lazy(() => import('./views/admin/groups/GroupCreateView')),
      },
      {
        id: 13,
        exact: true,
        path: '/admin/categorias/:groupId/editar',
        component: lazy(() => import('./views/admin/groups/GroupEditView')),
      },
      {
        id: 14,

        exact: true,
        path: '/admin/equipos',
        component: lazy(() => import('./views/admin/teams/TeamListView')),
      },
      {
        id: 15,
        exact: true,
        path: '/admin/equipos/nuevo',
        component: lazy(() => import('./views/admin/teams/TeamCreateView')),
      },
      {
        id: 16,
        exact: true,
        path: '/admin/equipos/:teamId/editar',
        component: lazy(() => import('./views/admin/teams/TeamEditView')),
      },
      {
        id: 17,
        exact: true,
        path: '/admin/jugadores',
        component: lazy(() =>
          import('./views/admin/teammembers/TeamMemberListView')
        ),
      },
      {
        id: 18,
        exact: true,
        path: '/admin/jugadores/nuevo',
        component: lazy(() =>
          import('./views/admin/teammembers/TeamMemberCreateView')
        ),
      },
      {
        id: 19,
        exact: true,
        path: '/admin/jugadores/:teamMemberId/editar',
        component: lazy(() =>
          import('./views/admin/teammembers/TeamMemberEditView')
        ),
      },
      {
        id: 20,
        exact: true,
        path: '/admin/tiendas/',
        component: lazy(() => import('./views/admin/stores/StoreListView')),
      },
      {
        id: 21,
        exact: true,
        path: '/admin/tiendas/nueva',
        component: lazy(() => import('./views/admin/stores/StoreCreateView')),
      },
      {
        id: 22,
        exact: true,
        path: '/admin/tiendas/:storeId/editar',
        component: lazy(() => import('./views/admin/stores/StoreEditView')),
      },
      {
        id: 23,
        exact: true,
        path: '/admin/vistasHome/',
        component: lazy(() => import('./views/admin/home/homeItemListView')),
      },
      {
        id: 24,
        exact: true,
        path: '/admin/vistasHome/nueva',
        component: lazy(() => import('./views/admin/home/homeItemCreateView')),
      },
      {
        id: 25,
        exact: true,
        path: '/admin/vistasHome/:homeItemId/editar',
        component: lazy(() => import('./views/admin/home/homeItemEditView')),
      },
      {
        id: 26,
        exact: true,
        path: '/admin/packs',
        component: lazy(() => import('./views/admin/packs/PackListView')),
      },
      {
        id: 27,
        exact: true,
        path: '/admin/packs/nuevo',
        component: lazy(() => import('./views/admin/packs/PackCreateView')),
      },
      {
        id: 28,
        exact: true,
        path: '/admin/packs/:packId/editar',
        component: lazy(() => import('./views/admin/packs/PackEditView')),
      },
      {
        id: 29,
        exact: true,
        path: '/admin/pedidos',
        component: lazy(() => import('./views/admin/orders/OrderListView')),
      },
      {
        id: 30,
        exact: true,
        path: '/admin/pedidos/:orderId',
        component: lazy(() => import('./views/admin/orders/OrderDetailsView')),
      },
      {
        id: 31,
        exact: true,
        path: '/admin/reportes',
        component: lazy(() => import('./views/admin/reports')),
      },
      {
        id: 31,
        exact: true,
        path: '/admin/cupones',
        component: lazy(() => import('./views/admin/coupons/CouponListView')),
      },
      {
        id: 31,
        exact: true,
        path: '/admin/cupones/nuevo',
        component: lazy(() => import('./views/admin/coupons/CouponCreateView')),
      },
      {
        id: 31,
        exact: true,
        path: '/admin/cupones/:couponId/editar',
        component: lazy(() => import('./views/admin/coupons/CouponEditView')),
      },
      {
        id: 32,
        exact: true,
        path: '/admin/cupones/:couponId/detalle',
        component: lazy(() => import('./views/admin/coupons/CouponDetailView')),
      },
      {
        id: 49,
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    id: 50,
    path: '/',
    layout: EcommerceLayout,
    routes: [
      {
        id: 51,
        exact: true,
        path: '/',
        component: lazy(() => import('./views/ecommerce/Home')),
      },
      {
        id: 52,
        exact: true,
        path: '/comprar',
        component: lazy(() => import('./views/ecommerce/ProductsView')),
      },
      {
        id: 53,
        exact: true,
        path: '/chutemundo',
        component: lazy(() => import('./views/ecommerce/ChuteWorld')),
      },
      {
        id: 54,
        exact: true,
        path: '/creator',
        component: lazy(() => import('./views/ecommerce/CreatorView')),
      },
      {
        id: 55,
        exact: true,
        path: '/tiendas',
        component: lazy(() => import('./views/ecommerce/Stores')),
      },
      {
        id: 56,
        exact: true,
        path: '/reglas',
        component: lazy(() => import('./views/ecommerce/HowToPlayView')),
      },
      {
        id: 57,
        exact: true,
        path: '/contacto',
        component: lazy(() => import('./views/ecommerce/Contact')),
      },
      {
        id: 58,
        exact: true,
        path: '/tbk-final/:tbkPaymentId/:orderId',
        component: lazy(() => import('./views/ecommerce/Payments/TbkFinal')),
      },
      {
        id: 59,
        exact: true,
        path: '/tbk-reject',
        component: lazy(() => import('./views/ecommerce/Payments/TbkReject')),
      },
      // {
      //   component: () => <Redirect to="/" />,
      // },
    ],
  },
  {
    id: 100,
    component: () => <Redirect to="/404" />,
  },
]

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route) => {
          const Guard = route.guard || Fragment
          const Component = route.component
          const Layout = route.layout || Fragment

          return (
            <Route
              key={route.id}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  ) : null

function Routes() {
  return renderRoutes(routesConfig)
}

export default Routes
